<template>
  <b-container class="page-container">
    <bread-crumbs :currentpage="$t('log-in')" />
    <b-alert v-if="notificationMessage" show variant="info">{{
      notificationMessage
    }}</b-alert>
    <h1>{{ $t('log-in') }}</h1>
    <p>
      {{ $t('login-to-rent-bike') }}
    </p>

    <b-row>
      <b-col sm="12" class="mb-3"> </b-col>
      <b-col sm="12" lg="6" xl="6">
        <login />
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import Login from '@/components/login/Login.vue'
export default {
  metaInfo: {
    title: `Inloggen`
  },
  components: {
    Login
  },
  computed: {
    notificationMessage: function() {
      if (this.$route.query.reason === 'loggedOut')
        return this.$t('message.logoutSuccess')

      return null
    }
  },
  created: function() {
    if (this.$route.params.registered) {
      this.notify({ message: this.$t('account-creation-succesfull') })
    }
  },
  methods: {
    notify({ message }) {
      this.$toasted.show(message)
    }
  }
}
</script>
<style scoped></style>
